import React from "react";
import Helmet from "react-helmet";
import { ISEOMetaItemType1, ISEOMetaItemType2 } from "../types/common";

export interface IProps {
  siteData: any;
  title?: string;
  description?: string;
  imageUrl?: string;
  meta?: Array<ISEOMetaItemType1 | ISEOMetaItemType2>;
}

function Container(props: IProps) {
  const metaTitle = props.title
    ? props.title
    : props.siteData.siteMetadata.title;
  const metaDescription =
    props.description || props.siteData.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: "ko",
      }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: "og:image",
          content: props.imageUrl || require("../images/quskr.png"),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: "summary",
        },
        // twitter 이미지는 full path가 들어가야함
        {
          name: `twitter:image`,
          content: `https://qus.kr${props.imageUrl ||
            require("../images/quskr.png")}`,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(props.meta || [])}
    />
  );
}

export default Container;
